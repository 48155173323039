

class InitnewsletterHeader {

  constructor(options = {}) {

    let defaults = {
      open_btn : '.as--type-newsletter',
      open_btn_search : '.as--type-search',
      close_btn: '.header-newsletter-cta',
      hidden_element: '.sm-header-filters',
      newsletter: '.header-newsletter',
      search: '.header-search',
      activeClass: 'is--active'
    };

    // fusionne les options renseignees avec celles par defaut pour creer l'objet settings
    this.settings = $.extend({}, defaults, options);

    // evenements par utilisateur ex scroll hover clic touch
    this.bindUI();
  }

  bindUI() {
    const self = this;
    const $open = $(self.settings.open_btn);
    const $close = $(self.settings.close_btn);

    $open.on('click touch', function(){
      self.openNewsletter();
    });

    $close.on('click touch', function(){
      self.closeNewsletter();
    });
  }

  openNewsletter(){
    const self = this;
    const $open = $(self.settings.open_btn);
    const $openSearch = $(self.settings.open_btn_search);
    const $h_item = $(self.settings.hidden_element);
    const $newsletter = $(self.settings.newsletter);
    const $search = $(self.settings.search);

    if(!$open.hasClass(self.settings.activeClass)) {
      $open.addClass(self.settings.activeClass);
      $h_item.fadeOut();
      $search.fadeOut();
      $openSearch.removeClass(self.settings.activeClass);
      setTimeout(function(){
        $newsletter.fadeIn();
        $('#input_newsletter').focus();
      }, 500);
    }
  }

  closeNewsletter(){
    const self = this;
    const $open = $(self.settings.open_btn);
    const $h_item = $(self.settings.hidden_element);
    const $newsletter = $(self.settings.newsletter);

    let $formI = $(this).next('form').find('input');
    $formI.val('');
    if($open.hasClass(self.settings.activeClass)) {
      $open.removeClass(self.settings.activeClass);
      $newsletter.fadeOut();
      setTimeout(function(){
        $h_item.fadeIn();
      }, 500);
    }
  }
}

module.exports = InitnewsletterHeader;
