

class InitsearchHeader {

  constructor(options = {}) {

    let defaults = {
      open_btn : '.as--type-search',
      open_btn_newsletter : '.as--type-newsletter',
      close_btn: '.header-search-cta',
      hidden_element: '.sm-header-filters',
      search: '.header-search',
      newsletter: '.header-newsletter',
      activeClass: 'is--active'
    };

    // fusionne les options renseignees avec celles par defaut pour creer l'objet settings
    this.settings = $.extend({}, defaults, options);

    // evenements par utilisateur ex scroll hover clic touch
    this.bindUI();
  }

  bindUI() {
    const self = this;
    const $open = $(self.settings.open_btn);
    const $close = $(self.settings.close_btn);

    $open.on('click touch', function(){
      self.openSearch();
    });

    $close.on('click touch', function(){
      self.closeSearch();
    });
  }

  openSearch(){
    const self = this;
    const $open = $(self.settings.open_btn);
    const $openNlt = $(self.settings.open_btn_newsletter);
    const $h_item = $(self.settings.hidden_element);
    const $search = $(self.settings.search);
    const $newsletter = $(self.settings.newsletter);

    if(!$open.hasClass(self.settings.activeClass)) {
      $open.addClass(self.settings.activeClass);
      $newsletter.fadeOut();
      $openNlt.removeClass(self.settings.activeClass);
      $h_item.fadeOut();
      setTimeout(function(){
        $search.fadeIn();
        $('#input_search').focus();
      }, 500);
    }
  }

  closeSearch(){
    const self = this;
    const $open = $(self.settings.open_btn);
    const $h_item = $(self.settings.hidden_element);
    const $search = $(self.settings.search);

    let $formI = $search.find('form').find('input');
    $formI.val('');
    if($open.hasClass(self.settings.activeClass)) {
      $open.removeClass(self.settings.activeClass);
      $search.fadeOut();
      setTimeout(function(){
        $h_item.fadeIn();
      }, 500);
    }
  }
}

module.exports = InitsearchHeader;
