'use strict';

/**
 *
 * Global variables
 * They can be called from any JS Class, provided they are imported
 *
 * @author mha
 */
function getdata(name) {
  return $('meta[property=' + JSON.stringify(name) + ']').attr('content');
}
const store = {
  projectJsName: getdata('app:site_data:projectJsName'),
  aAvailableMarkersType: getdata('app:site_data:aAvailableMarkersType'),
  sRootPath: getdata('app:site_data:sRootPath'),
  sMarkersPath: getdata('app:site_data:sMarkersPath'),
  defaultRequiredMsg: getdata('app:site_data:defaultRequiredMsg'),
  defaultErrorMsg: getdata('app:site_data:defaultErrorMsg'),
  defaultPwdErrorMsg: getdata('app:site_data:defaultPwdErrorMsg'),
  wWidth: 0,
  wHeight: 0,
  currentWidth: 0,
  currentHeight: 0,
  timerResponsive: 0,
  wScroll: 0,
  a11y: false,
  firstLoad: true,
  throttled: false,
  delayThrottled: 800,
  $body: $('body'),
  articleIsOpen: false,
  fromHistory: false,
  youtube_players: [],
  mq1: 'only screen and (max-width: 25em)',
  mq2: 'only screen and (max-width: 32em)',
  mq3: 'only screen and (max-width: 38em)',
  mq3bis: 'only screen and (max-width: 47em)',
  mq4: 'only screen and (max-width: 56em)',
  mq5: 'only screen and (max-width: 75em)',
  mq5bis: 'only screen and (max-width: 85em)',
  mq6: 'only screen and (max-width: 88em)',
  mq7: 'only screen and (max-width: 106em)'
};

module.exports = store;
