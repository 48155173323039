/**
 *
 * AutoTyping
 * Generic class to have auto typing text rotation
 *
 * @author afo
 */

class AutoTyping {

    /**
     *
     * Constructor
     *
     * @param options Object settings
     */

    constructor(options = {}) {

        const defaults = {
            autoTypingPlaceholder: '.auto-type',
            autoTypingInterval: 2000,
            autoTypingOnce: false,
            autoTypingDelay:0
        };

        // fusionne les options renseignees avec celles par defaut pour creer l'objet settings
        this.settings = $.extend({}, defaults, options);

        // evenements par utilisateur ex scroll hover clic touch
        this.bindUIActions();
    }


    /**
     *
     * Bind UI Actions
     *
     */

    bindUIActions() {

        var TxtType = function(el, toRotate, interval ,delay, typeOnce) {
            const myTxtType = this;
            myTxtType.toRotate = toRotate;
            myTxtType.el = el;
            myTxtType.loopNum = 0;
            myTxtType.period = interval;
            myTxtType.txt = '';
            myTxtType.typeOnce = typeOnce;
            if(delay > 0) {
                setTimeout(function() {
                    myTxtType.tick();
                }, delay);
            } else {
                myTxtType.tick();
            }
            myTxtType.isDeleting = false;
        };

        TxtType.prototype.tick = function() {
            var i = this.loopNum % this.toRotate.length;
            var fullTxt = this.toRotate[i];

            if(this.typeOnce === true && this.isDeleting === true) {
                this.el.addClass('typing-done');
                return;
            }

            if (this.isDeleting) {
                this.txt = fullTxt.substring(0, this.txt.length - 1);
            } else {
                this.txt = fullTxt.substring(0, this.txt.length + 1);
            }
            this.el.text(this.txt);

            var that = this;
            var delta = 200 - Math.random() * 100;

            if (this.isDeleting) {
                delta /= 3;
            }

            if (!this.isDeleting && this.txt === fullTxt) {
                delta = this.period;
                this.isDeleting = true;
            } else if (this.isDeleting && this.txt === '') {
                this.isDeleting = false;
                this.loopNum++;
                delta = 500;
            }

            setTimeout(function() {
                that.tick();
            }, delta);
        };

        const self = this;

        $(self.settings.autoTypingPlaceholder).each(function(i , typingPlaceholder) {
            const $typingPlaceholder = $( typingPlaceholder );
            const cyclingText = $typingPlaceholder.data('typetext');
            if($typingPlaceholder.is('[data-typeonce]')) {
                self.settings.autoTypingOnce = true;
            }
            if($typingPlaceholder.is('[data-typeinterval]')) {
                self.settings.autoTypingInterval =  $typingPlaceholder.data('typeinterval');
            }
            if($typingPlaceholder.is('[data-typedelay]')) {
                self.settings.autoTypingDelay =  $typingPlaceholder.data('typedelay');
            }
            new TxtType($typingPlaceholder, cyclingText, self.settings.autoTypingInterval, self.settings.autoTypingDelay, self.settings.autoTypingOnce);
        });
    }
}

module.exports = AutoTyping;
