'use strict';

/*global Modernizr */
import store from './_store';
import helpers from './_helpers';
import Collapsible from './class/Collapsible';
import ScrollAnchor from './class/ScrollAnchor';
import ShowPassword from './class/ShowPassword';
import ClearInput from './class/ClearInput';
import DetectBrowser from './class/DetectBrowser';
import Cookies from './class/Cookies';
import BannerMessages from './class/BannerMessages';
import InitMenuAction from './class/InitMenuAction';
import InitsearchHeader from './class/InitSearchHeader';
import InitnewsletterHeader from './class/InitNewsletterHeader';
import Newsletter from './class/Newsletter';
import AutoTyping from './class/AutoTyping';
import AOS from 'aos';
// import ValidForm from './class/ValidForm';
// import AnimatedLabelField from './class/AnimatedLabelField';
// import Colorbox from './class/Colorbox';


/**
 *
 * App
 * Main Controller
 *
 * @author acti
 * (vincegobelins, mha, efr, ...)
 */

const app = {
  init: function () {
      this.bindUI();

    /*--- initialisation des tailles de viewport ---*/
    store.currentWidth = store.wWidth = helpers.viewport().width;
    store.currentHeight = store.wHeight = helpers.viewport().height;

    store.wScroll = $(window).scrollTop();

    let self = this;

    /*--- detectBrowser ---*/
    let detectBrowser = new DetectBrowser();

    /*--- Validation Form ---*/
   /* let validForm = new ValidForm({
      container: '.valid-form',
      fieldContainer: '.form-field',
      validClass: 'as--valid',
      invalidClass: 'as--invalid',
      msgErrorClass: 'form-msg-error',
    });*/

   let autoTyping = new AutoTyping();

    /*--- cookies ---*/
    store.cookies = new Cookies();

    /*--- Banner messages (cookies consent / warnig / news...) ---*/
    const messagesBanner = new BannerMessages(/*{
      //caping: 3,
    }*/);

    /*--- Skip links ---*/
    let skip = new Collapsible({
      container: '.skip',
      cta: '.skip-cta',
      className: 'as--focused',
      optionHover: true
    });

    /*--- animation scroll ---*/
    /* Use '.js-scroll-anchor' class to trigger smooth anchor scroll*/
    store.scrollAnchor = new ScrollAnchor({
        delay: 800
    });

    /*--- password ---*/
    let showPassword = new ShowPassword();

    /*--- clear input ---*/
    let clearInput = new ClearInput();

    // responsive
    self.onResize();

    store.initMenuAction = new InitMenuAction();
    store.initsearchHeader = new InitsearchHeader({
      hidden_element: '#header .at-header-menu'
    });
    store.initnewsletterHeader = new InitnewsletterHeader({
      hidden_element: '#header .at-header-menu'
    });
    store.initNewsletter = new Newsletter();
  },

  checkMobile: function () {
    if (/Android|webOS|iPhone|iPad|BlackBerry|Windows Phone|Opera Mini|IEMobile|Mobile/i.test(navigator.userAgent)) {
      return true;
    }
  },

  onResize: function () {
    let self = this;
  },

  onScroll: function () {
    let self = this;
  },

  bindUI: function () {
    let self = this;

    let bFiguresViewed = false;
    const $keyFigures = $('.key-figures');
    let keyFiguresOffset;

    if (Modernizr.mq('only all')) {
      $(window).on('resize', function () {
        store.wWidth = helpers.viewport().width;
        store.wHeight = helpers.viewport().height;
        if (store.currentHeight !== store.wHeight || store.currentWidth !== store.wWidth) {
          store.currentHeight = store.wHeight;
          store.currentWidth = store.wWidth;
          /*--- timer pour le redimensionnement d'ecran ---*/
          clearTimeout(store.timerResponsive);
          store.timerResponsive = setTimeout(self.onResize.bind(self), 300);
        }
      });
    }

    document.onreadystatechange = function () {
      if (document.readyState === 'complete') {
        self.onResize();
      }
    };

    /*--- fonctions au scroll ---*/
    $(window).on('scroll', function () {
      store.wScroll = $(window).scrollTop();

      if($keyFigures.length) {
          keyFiguresOffset = $keyFigures.offset().top;
          // console.log('test :' + (numbersOffset - store.wHeight / 1.3) + ' / VH :' + store.wHeight + ' / SH :' +  store.wScroll);

          if(store.wScroll > (keyFiguresOffset - store.wHeight / 1.3)) {
              if(!bFiguresViewed) {
                  setTimeout(function(){
                      $('.odometer').each(function() {
                          var targetNumber = $(this).attr('data-targetNumber');
                          $(this).text(targetNumber);
                      });
                  }, 0);
                  bFiguresViewed = true;
              }
          }
      }

      self.onScroll();
    });


      AOS.init({
          duration: 800,
          easing: 'ease-out-back',
          once: true
      });


      let $hash = $(window.location.hash);
      if( $hash.length !== 0 ){
        setTimeout(function(){
          let offsetTop = $hash.offset().top;
          $('body, html').animate({scrollTop: (offsetTop)}, 800);
        }, 350);
      }

      $.scrollify({
          section: '.scroll-section',
          sectionName: 'section-name',
          interstitialSection: '',
          easing: 'easeOutExpo',
          scrollSpeed: 1100,
          offset: 0,
          scrollbars: true,
          standardScrollElements: '',
          setHeights: false,
          overflowScroll: true,
          updateHash: false,
          touchScroll: false,
          before: function() {},
          after: function() {
              let $current = $.scrollify.current();
              $('.scroll-section').removeClass('is-current');
              $current.addClass('is-current');
          },
          afterResize:  AOS.refresh,
          afterRender:   AOS.refresh
      });

      // use $.scrollify.update() to recalculate height of panels section

      if ($('.touchevents').length) {
          $.scrollify.disable();
      }

      $('.slider').slick({
          arrows: false,
          dots: true,
          variableWidth: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
      });


      $('#body').on('click','.js-inner-anchor', function() {
          $.scrollify.disable();
      });

      $('#body').on('click','.js-inner-anchor', function() {
          $.scrollify.disable();
      });

      if($('.text-push').length) {
        let $links =  $('.text-push li');
        $links.on('touchstart', function(){
          let $link = $(this);
          if( !$link.hasClass('as--opened') ) {
            $link.addClass('as--opened').siblings().removeClass('as--opened');
            setTimeout(function(){
              let positionThis = $link.offset().top;
              $('html, body').animate({scrollTop: positionThis-50}, 350);
            }, 700);
          }
        });
      }


    if($('.filtered-list').length) {
      store.firstFilter = true;
      const $listContainer =  $('.filtered-list');
      const $loadMoreBtn = $('.js-more-items');
      const $filter = $('.filter-options:not(.as--talent-filter)');
      const $checkboxes = $filter.find('[type="checkbox"]:checked');
      const $checkboxesTeam = $('.as--talent-filter input');

      var $grid = $listContainer.isotope({
        itemSelector: '.filtered-list-item',
        layoutMode: 'masonry'
      });

      setTimeout(function() {
        $grid.isotope('layout');
        $listContainer.addClass('is-done');
        AOS.refresh();
      },1200);


      $checkboxes.change( function() {
        const slugArray = [];
        let currentUrl = window.location.href;

        /* LGU */
       // let that = $(this);
        let nbCb = $checkboxes.length;
        let nbCbChecked = $filter.find('[type="checkbox"]:checked').length;
        if (store.firstFilter) {
          if ((nbCb - 1) === nbCbChecked) {
            $(this).prop('checked', true).parent('label').siblings().find('input').prop('checked', false);
          }
          store.firstFilter = false;
        } else {
          if (nbCbChecked === 0) {
            $filter.find('[type="checkbox"]').prop('checked', true);
            store.firstFilter = true;
          } else if (nbCbChecked === nbCb) {
            store.firstFilter = true;
          }
        }
        /* LGU */

        $checkboxes.filter(':checked').each(function() {
          const dataType = $(this).data('type');
          const cbValue = $(this).val();
          // console.log(dataType+cbValue);
          slugArray.push(dataType+cbValue);
        });

        if(slugArray.length < $checkboxes.length) {
          for(let i=0; i < slugArray.length; i++ ) {
            if(i === 0) {
              currentUrl += '?'+slugArray[i];
            } else {
              currentUrl += '&'+slugArray[i];
            }
          }
        }
        // console.log(currentUrl);
        $.ajax({
          url: currentUrl,
          type: 'post',
          beforeSend: function () {
            $listContainer.closest('.listing').addClass('as--loading');
            $grid.isotope('remove', $grid.isotope('getItemElements'));
          },
          success: function (data) {
            const $items = $(data).find('.card-event');
            const nextPageLink = $(data).find('.js-more-items').attr('href');

            $grid.append($items).isotope('appended', $items);
            if (nextPageLink) {
              $loadMoreBtn.attr('href', nextPageLink).show();
            } else {
              $loadMoreBtn.fadeOut();
            }
            setTimeout(function () {
              $grid.isotope('layout');
              AOS.refresh();
              $listContainer.closest('.listing').removeClass('as--loading');
            }, 1200);
          }
        });
      });

      $loadMoreBtn.on('click', function (e) {
        e.preventDefault();
        const el = $(this);
        const url = el.attr('href');

        $.ajax({
          url: url,
          type: 'post',
          beforeSend: function () {
            $listContainer.closest('.listing').addClass('as--loading');
          },
          success: function (data) {
            const $items = $(data).find('.card-event');
            const nextPageLink = $(data).find('.js-more-items').attr('href');
            $grid.append($items).isotope('appended', $items);
            if (nextPageLink) {
              $loadMoreBtn.attr('href', nextPageLink).show();
            } else {
              $loadMoreBtn.fadeOut();
            }
            setTimeout(function () {
              $grid.isotope('layout');
              AOS.refresh();
              $listContainer.closest('.listing').removeClass('as--loading');
            }, 1200);
          }
        });
      });



      $checkboxesTeam.change( function() {
        // map input values to an array


        var inclusives = [];
        // inclusive filters from checkboxes
        $checkboxesTeam.each( function( i, elem ) {
          // if checkbox, use value if checked
          if ( elem.checked ) {
            inclusives.push( elem.value );          }
        });

        // combine inclusive filters
        var filterValue = inclusives.length ? inclusives.join(', ') : '*';
        $listContainer.find('.filtered-list-item').removeClass('visible left');
        $grid.isotope({ filter: filterValue });
        var elems = $grid.isotope('getFilteredItemElements');
        for (let j = 0; j < elems.length; j++) {
          elems[j].classList.add('visible');
          if (j % 2 === 0) {
            elems[j].classList.add('left');
          }
        }

        setTimeout(function() {
          AOS.refresh();
        },1500);
      });

    $checkboxesTeam.click( function() {
        let nbCb = $checkboxesTeam.length;
        let nbCbChecked =  $checkboxesTeam.filter(':checked').length;
        if (store.firstFilter) {
            if ((nbCb - 1) === nbCbChecked) {
                $(this).prop('checked', true).parent('label').siblings().find('input').prop('checked', false);
            }
            store.firstFilter = false;
        } else {
            if (nbCbChecked === 0) {
                $checkboxesTeam.prop('checked', true);
                store.firstFilter = true;
            } else if (nbCbChecked === nbCb) {
                store.firstFilter = true;
            }
        }
    });

      $checkboxesTeam.trigger('change');
    }

      if($('.js-reveal-btn').length) {
          const $revealBtn = $('.js-reveal-btn');
          const $revealList =  $('.js-reveal-list');
          const revealNumber = $revealBtn.data('reveal-number');
          $revealList.find('.card-person').eq(revealNumber).addClass('unrevealed').nextAll().addClass('unrevealed');
          if( $revealList.find('.card-person').length < revealNumber + 1) {
              $revealBtn.remove();
          }
          $revealBtn.on('click', function() {
              $revealList.find('.unrevealed').eq(0).removeClass('unrevealed').nextAll().slice(0, revealNumber - 1).removeClass('unrevealed');
              setTimeout(function() {
                  if(!($revealList.find('.unrevealed').length)) {
                      $revealBtn.fadeOut();
                  }
              },1000);
          });
      }
  }
};

app.init();

// global custom functions, they can be called from anywhere within the project (useful for the back-end developers)
let customFunctions = {
  // global custom function example
  // to call it from anywhere : global.customFunction.afterAjaxExample();
  /*afterAjaxExample: function() {
   helpers.resizeImg('.media-block-news');
   }*/
};
// exports the elements that need to be accessed from somewhere else (in the "global" standalone object, cf. gulpfile)
module.exports = customFunctions;
