class Newsletter {
  constructor(options = {}) {
    const defaults = {
      module: '.mailjet_widget_front_container form',
      response: 'span'
    };
    this.settings = $.extend({}, defaults, options);
    this.bindUI();
  }

  bindUI() {
    const self = this;
    let $module = $(self.settings.module);
    $module.each(function () {
      $(this).find(self.settings.response).addClass('as--hidden');
      $(this).on('submit', function (e) {

        e.preventDefault(); // avoid to execute the actual submit of the form.

        let form = $(this);
        let url = window.location.href;

        $.ajax({
          type: 'POST',
          url: url,
          data: form.serialize(), // serializes the form's elements.
          success: function (data) {
            //let resultText = $(data).find('#mjForm span').html();
            let $frontContainer = form.closest('.mailjet_widget_front_container');

            let $msgContainer = $('<span></span>').addClass('widget-text').appendTo($frontContainer);

            $(self.settings.module).hide();
            $msgContainer.html('Merci ! Nous venons de vous envoyer un mail afin de confirmer votre inscription.');
            $msgContainer.addClass('as--visible');
            $('.newsletter-message').hide();

          }
        });


      });
    });
  }
}

module.exports = Newsletter;
