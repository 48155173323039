import store from '../_store';

class InitMenuAction {

  constructor(options = {}) {

    let defaults = {
      project: 'sm',
      mHeader: '.header',
      toggleCta: '.header-cta:not(.as--type-search), .header:not(.is--open) .header-cta.as--type-search',
      hsl: '.header-side .header-link',
      activeClass: 'is--open',
      hiddenClass: 'is--hidden',
      openMenu: 'is--open-nav',
      overlay: '.header-overlay',
      smHeaderFooter: '.header-main-footer',
      smFilters: '.sm-header-filters'
    };

    // fusionne les options renseignees avec celles par defaut pour creer l'objet settings
    this.settings = $.extend({}, defaults, options);

    // evenements par utilisateur ex scroll hover clic touch
    this.bindUI();
  }

  /**
   *
   * Bind UI Actions
   *
   */

  bindUI() {
    const self = this;
    self.actionOnHeaderCta();
    self.actionOnOverlay();
  }
  actionOnHeaderCta() {
    const self = this;
    const $cta = $(self.settings.toggleCta);
    const $header = $(self.settings.mHeader);
    $cta.on('click touch', function(){
        if ($(this).hasClass('as--type-search') && $('html').hasClass(self.settings.openMenu)) {
          return false;
        } else {
          setTimeout(function () {
            $('html').toggleClass(self.settings.openMenu);
          }, 400);
        }

        if(!$(self.settings.hsl).hasClass(self.settings.hiddenClass)) {
          $(self.settings.hsl).addClass(self.settings.hiddenClass);
          setTimeout(function(){
            $(self.settings.mHeader).addClass(self.settings.activeClass);
            $(self.settings.smHeaderFooter).removeClass(self.settings.hiddenClass);
          },500);
          setTimeout(function(){
            $('.sm-header-filters').addClass('as--visible');
          },800);
        } else {
          $('.sm-header-filters').show().removeClass('as--visible');
          store.initsearchHeader.closeSearch();
          store.initnewsletterHeader.closeNewsletter();
          $('.header-newsletter').hide();
          setTimeout(function(){
            $(self.settings.smHeaderFooter).addClass(self.settings.hiddenClass);
            $(self.settings.mHeader).removeClass(self.settings.activeClass);
            self.specialFormClosing();
          },300);

          setTimeout(function(){
            $(self.settings.hsl).removeClass(self.settings.hiddenClass);
          },850);
        }
      /* else {
        if(!$(self.settings.mHeader).hasClass(self.settings.activeClass)) {
          $(self.settings.mHeader).addClass(self.settings.activeClass);
        } else {
          $(self.settings.mHeader).removeClass(self.settings.activeClass);
        }
      }*/

    });
  }
  actionOnOverlay() {
    const self = this;
    const $overlay = $(self.settings.overlay);

    $overlay.on('click touch', function(){
      self.closeNav();
    });

  }
  closeNav(){
    const self = this;
    if($('html').hasClass(self.settings.openMenu)) {
      setTimeout(function(){
        $('html').toggleClass(self.settings.openMenu);
      }, 500);
      if(self.settings.project === 'sm') {
        $('.sm-header-filters').removeClass('as--visible');
        setTimeout(function(){
          $(self.settings.smHeaderFooter).addClass(self.settings.hiddenClass);
          $(self.settings.mHeader).removeClass(self.settings.activeClass);
          self.specialFormClosing();
        },300);

        setTimeout(function(){
          $(self.settings.hsl).removeClass(self.settings.hiddenClass);
        },850);
      } else {
        $(self.settings.mHeader).removeClass(self.settings.activeClass);
      }
    }
  }
  specialFormClosing() {
    const self = this;
    const $smFilters = $(self.settings.smFilters);
    if($smFilters.hasClass('is--active')) {
      $smFilters.removeClass('is--active');
      $('.form-thematique-list').fadeOut();
    }
  }
}


module.exports = InitMenuAction;
